import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Safi</h1>
      <span >
        Sofware Engineer
      </span>

      <ul>
        <li><a href="https://www.linkedin.com/in/safi-eddine-bouhentala/">LinkedIn</a></li>
        <li><a href="https://github.com/apotox">Github</a></li>
        <li>-</li>
        <li ><a className='animatedBorder' target='_blank' rel="noreferrer" href="https://visitplan.info">Visitplan AI</a></li>
      </ul>
    </div>
  );
}

export default App;
